@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply h-12 border rounded center text-[12px] hover:opacity-90;
  }
}

@layer utilities {
  .center {
    @apply flex items-center justify-center;
  }
}

body {
  background-color: #fff;
}

input {
  outline: none;
}

.btn[disabled]{
  opacity: 0.5;
}

* {
  font-family: 'Sora' !important;
} 

.videoCard video {
  object-fit: cover;
}