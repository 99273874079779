.hg-theme-default .keyboard-custom-space-bar {
  flex: 10;
}

.hg-theme-default .keyboard-custom-number-style {
  flex: 1
}

.hg-theme-default .keyboard-custom-number-enter-style {
  flex: 1;
  color: #fff;
  background-color: #5f97f6;
}

.hg-theme-dark {
  background: #2e2d2d;
}

.hg-theme-dark .hg-button {
  background: #3d3d3d;
  border-bottom: none;
  color: #fff;
}

.hg-theme-dark .hg-button-enter {
  background: #F93D66;
}